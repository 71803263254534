import * as React from 'react'

// function resizeIframe3(id) {
//   console.log('resizeIframe3-1')
//   if (document.getElementById) {
//     console.log('resizeIframe3-2')
//     var iframeid = document.getElementById(id) //iframe id
//     console.log('resizeIframe3-2, iframeid=', iframeid)
//     if (iframeid && !window.opera) {
//       if (
//         iframeid.contentDocument &&
//         iframeid.contentDocument.body.offsetHeight
//       ) {
//         iframeid.height = iframeid.contentDocument.body.offsetHeight
//       } else if (iframeid.Document && iframeid.Document.body.scrollHeight) {
//         iframeid.height = iframeid.Document.body.scrollHeight
//       }
//     }
//     console.log(
//       'resizeIframe3: iframeid.contentWindow=',
//       iframeid.contentWindow
//     )
//     console.log(
//       'resizeIframe3: iframeid.contentDocument=',
//       iframeid.contentDocument
//     )
//     console.log('resizeIframe3: iframeid.Document=', iframeid.Document)

//     if (iframeid.contentWindow) {
//         console.log('resizeIframe3-2-1, iframeid.contentWindow.document=', iframeid.contentWindow.document)
//       console.log(
//         'resizeIframe3: iframeid.contentWindow.document.body.scrollHeight=',
//         iframeid.contentWindow.document.body.scrollHeight
//       )
//       console.log(
//         'resizeIframe3: iframeid.contentWindow.document.body.offsetHeight=',
//         iframeid.contentWindow.document.body.offsetHeight
//       )
//       console.log(
//         'resizeIframe3: iframeid.contentWindow.document.documentElement.scrollHeight=',
//         iframeid.contentWindow.document.documentElement.scrollHeight
//       )
//       console.log(
//         'resizeIframe3: iframeid.contentWindow.document.documentElement.offsetHeight=',
//         iframeid.contentWindow.document.documentElement.offsetHeight
//       )
//     }

//     if (iframeid.contentDocument) {
//         console.log('resizeIframe3-2-2')
//       console.log(
//         'resizeIframe3: iframeid.contentDocument.body.scrollHeight=',
//         iframeid.contentDocument.body.scrollHeight
//       )
//       console.log(
//         'resizeIframe3: iframeid.contentDocument.body.offsetHeight=',
//         iframeid.contentDocument.body.offsetHeight
//       )
//     }

//     if (iframeid.contentDocument) {
//         console.log('resizeIframe3-2-3')
//       console.log(
//         'resizeIframe3: iframeid.Document.body.scrollHeight=',
//         iframeid.Document.documentElement.scrollHeight
//       )
//       console.log(
//         'resizeIframe3: iframeid.Document.body.offsetHeight=',
//         iframeid.Document.documentElement.offsetHeight
//       )
//     }
//   }
//   console.log('resizeIframe3-3')
// }


function OnLoad(frame: Window, iframeId: string) {
  //   console.log('TEST2, frame=', frame, ', height=', frame.document.documentElement.offsetHeight)
  const iframe = document.getElementById(iframeId)
  if (iframe)
      iframe.height = frame.document.documentElement.offsetHeight

  const fullscreenChange = () => {
    if (
      document.fullscreenEnabled ||
      document.webkitIsFullScreen ||
      document.mozFullScreen ||
      document.msFullscreenElement
    ) {
      console.log('enter fullscreen')
    } else {
      console.log('exit fullscreen')
    }
    // force to reload iframe once to prevent the iframe source didn't care about trying to resize the window
    // comment this line and you will see
    // var iframe = document.querySelector('iframe')
    if (iframe)
        iframe.src = iframe.src
  }

  const enterFullscreen = () => {
      if (iframe)
      {
          if (iframe.requestFullscreen) {
            iframe.requestFullscreen()
          } else if (iframe.webkitRequestFullscreen) {
            iframe.webkitRequestFullscreen()
          } else if (iframe.mozRequestFullScreen) {
            iframe.mozRequestFullScreen()
          } else if (iframe.msRequestFullscreen) {
            iframe.msRequestFullscreen()
          } else {
            // document.querySelector('.error').innerHTML = '瀏覽器不支援全螢幕功能'
          }
      }
  }

  const exitFullscreen = () => {
      if (document.exitFullscreen) {
        document.exitFullscreen()
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen()
      } else {
        // document.querySelector('.error').innerHTML = '瀏覽器不支援全螢幕功能'
      }
  }

  // detect enter or exit fullscreen mode
  document.addEventListener('webkitfullscreenchange', fullscreenChange)
  document.addEventListener('mozfullscreenchange', fullscreenChange)
  document.addEventListener('fullscreenchange', fullscreenChange)
  document.addEventListener('MSFullscreenChange', fullscreenChange)

  window.addEventListener('message', function (event) {
    if (event.data == 'plot-fullscreen') {
      enterFullscreen()
    } else if (event.data == 'plot-restore') {
      exitFullscreen()
    }
  })
}

export default function AutoHeightIFrame({ iframeId, url }) {
  return (
    <iframe
      src={url}
      id={iframeId}
      width='100%'
      frameBorder='0'
      scrolling='no'
      style={{
        marginBottom: 20,
        marginTop: 10,
        marginLeft: 0,
        marginRight: 0
      }}
      onLoad={() => OnLoad(self, iframeId)}
    ></iframe>
  )
}

// https://stackoverflow.com/questions/67218249/automatically-adjust-height-of-iframe-using-react-hooks
// export function FrameWrapper() {
//   const ref = React.useRef()
//   const [height, setHeight] = React.useState('0px')
//   const onLoad = () => {
//     setHeight(ref.current.contentWindow.document.body.scrollHeight + 'px')
//   }
//   return (
//     <iframe
//       ref={ref}
//       onLoad={onLoad}
//       id='myFrame'
//       src='https://qiyoapps.com/plot/backtesting.plot.html'
//       width='100%'
//       height={height}
//       scrolling='no'
//       frameBorder='0'
//       style={{
//         maxWidth: 640,
//         width: '100%',
//         overflow: 'auto'
//       }}
//     ></iframe>
//   )
// }

// export const FrameWrapper2 = ({ html }) => {
//   const ref = React.useRef<HTMLIFrameElement>()
//   const [height, setHeight] = React.useState('0px')

//   const onLoad = () => {
//     setHeight(ref.current?.contentWindow?.document.body.scrollHeight + 'px')
//   }
//   React.useEffect(() => {
//     onLoad()
//   }, [])

//   return (
//     <iframe
//       ref={ref}
//       onLoad={onLoad}
//       className='w-full'
//       id='iframe'
//       src='https://qiyoapps.com/plot/backtesting.plot.html'
//       height={height}
//       scrolling='no'
//       frameBorder='0'
//     ></iframe>
//   )
// }

// Other Ref:
// https://community.ibm.com/community/user/automation/discussion/how-to-set-height-of-iframe-dynamically-with-react-js
// https://blog.csdn.net/qq_39949109/article/details/83305932
// https://developer.mozilla.org/en-US/docs/Web/API/HTMLIFrameElement/contentWindow
// https://stackoverflow.com/questions/9975810/make-iframe-automatically-adjust-height-according-to-the-contents-without-using
// https://blog.poychang.net/javascript-iframe/
