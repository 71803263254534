import * as React from 'react';
import { navigate } from 'gatsby';
import TabImages from '@qiyo_components/TabImages.tsx';
import EarningsCalls from '@qiyo_components/EarningsCalls.tsx';
import { ImageZoom } from '@qiyo_components/ImageZoom';
import Divider from '@components/Divider';
import AutoHeightIFrame from '@qiyo_components/AutoHeightIFrame.tsx';
import ScrollToTop from "react-scroll-to-top";
export default {
  React,
  navigate,
  TabImages,
  EarningsCalls,
  ImageZoom,
  Divider,
  AutoHeightIFrame,
  ScrollToTop
};